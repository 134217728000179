@import '../../variables.scss';

.privacy-policy {
  background-color: white;

  .privacy-policy-title-wrapper {
    padding: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .privacy-policy-main-container {
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 1120px;
    margin: 0 auto;

    >.columns {
      column-count: 2;
      column-gap: 0;
      flex: 1;
      @media (max-width: 960px) {
        column-count: 1;
      }
    }

    @media (max-width: 1440px) {
      margin: 0 5vw;

      p {
        word-break: break-word;
      }
    }

    .privacy-policy-main-container-text {
      page-break-inside: avoid;
      padding: 20px;
      width: 540px;
      max-width: 540px;
      @media (max-width: 1440px) {
        width: 100%;
        max-width: 100%;
      }

      .privacy-policy-main-container-text-title {
        padding-bottom: 28px;
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }
  }

  ol {
    list-style: decimal inside none;
    margin-bottom: 1rem;
  }
}
